<template>
  <div class="main">
    <div class="header">
      <div class="header-center fl">
        <div class="header-title">
          <img
            src="./css/images/logo.png"
            style="vertical-align: middle"
          />基于数字孪生技术的典型<br>配网设备智能运维和预测性检修策略平台
        </div>
        <div class="header-img"></div>
      </div>
      <div class="header-bottom fl"></div>
    </div>
    <div class="content">
      <div class="tyg-div">
        <el-image :src="imageUrl"></el-image>
      </div>
      <div class="content-left"></div>
      <div class="content-right">
        <div class="login-title">
          <span>用户登录</span>
        </div>
        <div class="right-infp">
          <el-form
            ref="params"
            :model="params"
            :rules="rules"
            label-position="left"
            class="login-form right-infp-name"
          >
            <el-form-item class="right-infp-name" prop="username">
              <el-input
                v-model="params.username"
                placeholder="请输入用户名"
                name="username"
                type="text"
                autocomplete="on"
                prefix-icon="icon el-icon-user"
              />
            </el-form-item>
            <el-tooltip
              v-model="capsTooltip"
              content="大写锁定已打开"
              placement="right"
              manual
            >
              <el-form-item class="right-infp-name" prop="password">
                <el-input
                  v-model="params.password"
                  placeholder="请输入密码"
                  name="password"
                  prefix-icon="icon el-icon-key"
                  show-password
                  @keyup.native="checkCapslock"
                  @blur="capsTooltip = false"
                  @keyup.enter.native="submitForm"
                  autocomplete="off"
                />
              </el-form-item>
            </el-tooltip>
            <el-checkbox class="remPass" v-model="params.rem"
              >记住密码,下次直接登录</el-checkbox
            >
            <br />
            <br />
            <div @click.prevent="submitForm" class="right-infp-btn">
              <el-button :loading="loading" type="primary" class="btn"
                >登录
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin, getAllAuthList } from "@/api/index";
import store from "@/store/index";
export default {
  name: "Login",
  data() {
    return {
      submitState: false,
      imageUrl: require("./css/images/bdz.png"),
      urlSrc: require("./css/images/logo.png"),
      loginFormRef: null,
      capsTooltip: false,
      loading: false,
      params: {
        //封装登录输入数据
        username: "",
        password: "",
        rem: false,
      },
      registerParams: {
        phone: "",
        sys_num: "",
        username: "",
        password1: "",
        password2: "",
        email: "",
      },
      sending: true, //是否发送验证码
      second: 60, //倒计时间
      codeTest: "获取验证码",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password1: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password2: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
          // { validator: validatePass2, trigger: 'blur', required: true }
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator(rule, value, callback) {
              //debugger
              if (!/^1[34578]\d{9}$/.test(value)) {
                return callback(new Error("手机号码格式不正确"));
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { max: 50, message: "邮箱在50字以内", trigger: "blur" },
          {
            pattern:
              /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: "请检查邮箱格式是否正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getCookie();
  },
  methods: {
    submitForm() {
      this.$refs["params"].validate((valid) => {
        if (valid) {
          this.submitState = true;
          const self = this;
          if (self.params.rem == true) {
            //传入账号名，密码，和保存天数3个参数
            self.setCookie(
              self.params.username,
              self.params.password,
              self.params.rem,
              30
            );
          } else {
            //清空Cookie
            self.clearCookie();
          }
          userLogin(this.params)
            .then((res) => {
              if (res.code == 200) {
                let result = res.data;
                this.token = result.token;
                sessionStorage.setItem("token", this.token);
                sessionStorage.setItem("userInfo", this.params.username);
                sessionStorage.setItem("roleIds", JSON.stringify(res.roles_id));
                // 调用fetchPermissions action获取权限数据
                store.dispatch(
                  "fetchPermissions",
                  JSON.stringify(res.roles_id)
                );
                getAllAuthList(res.roles_id[0]).then((resp) => {
                // 检查权限列表并决定要导航到的路由
                  if (
                  resp.data &&
                  resp.data.length > 0
                ) {
                  // 假设我们要导航到权限列表中的第一个权限对应的路由
                  const firstPermission =  resp.data[0];
                  let routeName = firstPermission.remarks; // 这是一个你需要实现的函数
                  if (routeName) {
                    // 使用 Vue Router 导航到该路由
                    this.$router.push({ path: routeName });
                  } else {
                    // 如果没有找到对应的路由，可以导航到默认页面或首页
                    this.$router.push("/");
                  }
                } else {
                  // 如果没有权限列表或列表为空，导航到默认页面或首页
                  this.$router.push("/");
                }
                });
                
                // 接下来可以导航到主页或受保护的页面
                this.$message.success("登陆成功！");
                // 登录成功后跳转到保护页
                // this.$router.push("/data");
              } else {
                this.$message.error(result.error);
              }
              this.submitState = false;
            })
            .catch((error) => {
              this.submitState = false;
            });

          // this.token = "88888888888";
          // sessionStorage.setItem("token", this.token);
          // this.$message.success("登陆成功！");
          // this.$router.push("/data");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setCookie(c_name, c_pwd, c_status, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwdStatus" +
        "=" +
        c_status +
        ";path=/;expires=" +
        exdate.toGMTString();
    },
    //读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          //判断查找相对应的值
          if (arr2[0] == "userName") {
            this.params.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == "userPwd") {
            this.params.password = arr2[1];
          } else if (arr2[0] == "userPwdStatus") {
            if (arr2[1] == "true") {
              this.params.rem = true;
            } else {
              this.params.rem = false;
            }
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },
    validatePass2(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    checkPhone(rule, value, callback) {
      //debugger
      if (!/^1[34578]\d{9}$/.test(value)) {
        return callback(new Error("手机号码格式不正确"));
      }
      return callback();
    },
    //验证码倒计时
    timeDown() {
      let result = setInterval(() => {
        --this.second;
        this.codeTest = this.second + "秒后可重新获取";
        if (this.second < 1) {
          clearInterval(result);
          this.sending = true;
          //this.disabled = false;
          this.second = 60;
          this.codeTest = "获取验证码";
        }
      }, 1000);
    },
    //通过输入手机号发送验证码
    getCodeFun() {
      let phone = this.registerParams.phone; //手机号码
      let isMobile = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if (phone != "" && isMobile.test(phone) && phone.length == 11) {
        registerApi.sendCode(this.params.mobile).then((response) => {
          this.sending = false;
          //调用倒计时的方法
          this.timeDown();
        });
      }
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (
          (shiftKey && key >= "a" && key <= "z") ||
          (!shiftKey && key >= "A" && key <= "Z")
        ) {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }

      if (key === "CapsLock" && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  input {
    width: 90%;
    color: #fff;
    margin-top: 0.02rem;
    margin-left: 0.2rem;
    background: transparent;
    border: 0;
    border-radius: 0;
    caret-color: #fff;

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px #031836 inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }
}
/deep/ .el-checkbox {
  line-height: 0.5rem;
  font-size: 0.5rem;
}
/deep/ .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 18px;
}
/deep/ .el-checkbox__input{
  vertical-align:baseline !important;
}
/deep/ .el-input__icon{
  height: auto
}
@import url("./css/login.css");
</style>

